<template>
  <div class="view-admin-guidance-playbook">
    <loading-screen :is-loading="isDeleting"></loading-screen>

    <!-- Is Loading -->
    <div v-if="isLoadingCombined" class="loader">
      <a-spin></a-spin>
    </div>
    <!-- / Is Loading -->

    <div class="page-header" v-if="!isLoadingCombined && playbook">
      <h1 class="page-title">Playbook: {{ playbook["displayName"] }}</h1>
      <div class="actions">
        <a-button
          class="button-margin-left btn-rounded"
          icon="arrow-left"
          size="large"
          type=""
          @click.prevent="goBack"
          >Back</a-button
        >

        <a-button
          class="button-margin-left btn-rounded"
          icon="edit"
          size="large"
          type="primary"
          v-if="canEdit"
          @click.prevent="edit"
          >Edit</a-button
        >

        <a-popconfirm
          v-if="canEdit"
          title="Are you sure?"
          ok-text="Yes"
          cancel-text="No"
          @confirm="attemptDelete"
        >
          <a-button
            class="button-margin-left btn-rounded"
            icon="delete"
            size="large"
            type="danger"
            >Delete</a-button
          >
        </a-popconfirm>
      </div>
    </div>

    <!-- Checklist -->
    <div v-if="checklistContent">
      <h3 style="margin-bottom: 15px">Playbook Tasks</h3>
      <checklist
        :tenant-id="tenantId"
        :details="checklistContent['$v']"
      ></checklist>
    </div>
    <!-- / Checklist -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LoadingScreen from "../../../../components/LoadingScreen.vue";
const _ = require("lodash");
import checklists from "../../../../api/checklists";
import Checklist from "../../../../views/Guidance/Show/TopicTabs/Checklist.vue";

export default {
  components: {
    LoadingScreen,
    Checklist,
  },

  data() {
    return {
      isDeleting: false,
    };
  },

  methods: {
    goBack() {
       if (this.$route.query.backTo) {
        this.$router.push(this.$route.query.backTo);
      } else {
        this.$router.push("/admin/guidance/playbooks");
      }
    },

    edit() {
      this.$router.push(
        "/admin/guidance/playbooks/" + this.checklistId + "/edit"
      );
    },

    attemptDelete() {
      let vm = this;
      vm.isDeleting = true;
      checklists
        .deleteChecklistContent(vm.tenantId, vm.checklistContent["$v"].id)
        .then(() => {
          vm.isDeleting = false;
          vm.$message.success("Playbook removed successfully");
          vm.$router.push("/admin/guidance/playbooks");
        })
        .catch((e) => {
          vm.isDeleting = false;
          console.log(e);
          vm.$message.error("Error deleting playbook");
        });
    },
  },

  computed: {
    ...mapGetters("admin", {
      tenantId: "tenantId",
      selectedOrganisation: "selectedOrganisation",
      isLoading: "isLoading",
    }),

    ...mapGetters("adminPlaybooks", {
      isLoadingPlaybooks: "isLoadingPlaybooks",
      isLoadingChecklistContents: "isLoadingChecklistContents",
      playbooksToShow: "playbooksToShow",
      checklistContents: "checklistContents",
    }),

    ...mapGetters("missionControl", {
      idsOfOrganisationsIAmAnAdminFor: "idsOfOrganisationsIAmAnAdminFor",
    }),

    checklistId() {
      return this.$route.params.id;
    },

    playbook() {
      return _.find(this.playbooksToShow, { id: this.checklistId });
    },

    checklistContent() {
      let vm = this;
      return _.find(this.checklistContents, (cc) => {
        return cc["$v"]["checklistId"] == vm.checklistId;
      });
    },

    isLoadingCombined() {
      return (
        this.isLoading ||
        this.isLoadingChecklistContents ||
        this.isLoadingPlaybooks
      );
    },

    canEdit() {
      if (!this.checklistContent) {
        return false;
      }
      return this.idsOfOrganisationsIAmAnAdminFor.includes(
        this.checklistContent["$v"]["ownerId"]
      );
    },
  },
};
</script>

<style lang="scss">
.view-admin-guidance-playbook {
  padding-bottom: 40px;

  .loader {
    background: #fff;
    padding: 50px;
    text-align: center;
  }

  .button-margin-left {
    margin-left: 10px;
  }
}
</style>